<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12 text-left pl-1 pb-3>
        <span class="bold"> <h3>User View</h3></span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-card width="100%" tile>
          <v-layout wrap pa-3 pa-sm-5>
            <v-flex xs12 sm6 class="subhead">
              <v-layout wrap>
                <v-flex xs12>
                  <span class="sbold"> User Name :</span> &nbsp;
                  <span class="regular">
                    {{ user.first_name }} {{ user.last_name }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span class="sbold"> phone :</span> &nbsp;
                  <span class="regular"> {{ user.phone }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              sm6
              class="subhead"
              py-2
              v-if="totalLength == 0"
              text-center
              align-self-center
            >
              <span class="bold"> No Uploads Yet.....</span>
            </v-flex>
            <v-flex
              xs12
              sm6
              class="subhead"
              py-2
              v-else
              text-center
              align-self-center
            >
              <h4 style="text-transform: uppercase">
                Total Number of Sightings
              </h4>
              <span class="bold" style="color: green; font-size: 30px">{{
                totalLength
              }}</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 id="googlemap"> </v-flex>
    </v-layout>
    <v-layout wrap pt-3>
      <v-flex xs12 text-left pb-2>
        <span style="text-transform: uppercase" class="bold" large>
          <h3>Sightings</h3></span
        >
      </v-flex>
      <v-flex xs12 text-right>
        <v-layout wrap>
          <!-- <v-flex xs12 sm2 pb-2 pr-5>
            <v-select
              clearable
              :items="animalTypes"
              v-model="animalType"
              outlined
              label="animal Type"
              color="primary"
              dense
              class="rounded"
            >
            </v-select>
          </v-flex> -->
          <v-flex xs12 sm2 pb-2 pr-5>
            <v-select
              clearable
              :items="spotTypes"
              v-model="spotType"
              outlined
              label="Spot Type"
              color="primary"
              dense
              class="rounded"
            >
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 class="sbold">
        <!-- <v-data-table
          style="cursor: pointer"
          :headers="headers"
          :items="records"
          class="elevation-1"
          hide-default-footer
          @click:row="rowClicked"
        >
          <template v-slot:item.create_date="{ item }">
            <span>{{ new Date(item.create_date).toLocaleString() }}</span>
          </template>
        </v-data-table> -->

        <v-card outlined class="sbold">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left sbold" style="font-size: 15px">
                    Sr No.
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Netted or Free
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">Date</th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Name of user
                  </th>

                  <th class="text-left sbold" style="font-size: 15px">
                    Ph.Number
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Latitude
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Longitude
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Gender
                  </th>
                    <th class="text-left sbold" style="font-size: 15px">
                    Length (M)
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Boat name
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Boat number
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Owner name
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Condition
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Other info
                  </th>
                  <th class="text-left sbold" style="font-size: 15px">
                    Photos/Videos and more..
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- <tr v-for="(value, i) in report" :key="i"> -->
                <tr v-for="(value, i) in records" :key="i">
                  <!-- <td>{{ i + 1 }}</td> -->
                  <td>
                    <span v-if="page == 1">
                      {{ i + 1 }}
                    </span>
                    <span v-else>
                      {{ i + 1 + 20 * (page - 1) }}
                    </span>
                  </td>

                  <td v-if="value.field">
                    {{ value.field }}
                  </td>
                  <td v-if="value.create_date">
                    <!-- {{ formatDate(value.create_date) }} -->
                    {{ value.create_date.slice(0, 10) }}
                  </td>
                  <td v-if="value.userid">
                    <router-link
                      style="text-decoration: none; color: black"
                      :to="'/uploaddetail?id=' + value._id"
                    >
                      {{ value.userid.first_name
                      }}{{ value.userid.last_name }}</router-link
                    >
                  </td>
                  <td>
                    {{ value.userid.phone }}
                  </td>

                  <td v-if="value.location[1]">{{ value.location[1] }}</td>
                  <td v-else>-</td>
                  <td v-if="value.location[0]">{{ value.location[0] }}</td>
                  <td v-else>-</td>
                  <td v-if="value.gender">{{ value.gender }}</td>
                  <td v-else>-</td>
                   <td v-if="value.length">{{ value.length }}</td>
                  <td v-else>-</td>
                  <td v-if="value.boatname">{{ value.boatname }}</td>
                  <td v-else>-</td>

                  <td v-if="value.boatno">{{ value.boatno }}</td>
                  <td v-else>-</td>
                  <td v-if="value.ownerName">{{ value.ownerName }}</td>
                  <td v-else>-</td>
                  <td v-if="value.health">{{ value.health }}</td>
                  <td v-else>-</td>
                  <td v-if="value.description">{{ value.description }}</td>
                  <td v-else>-</td>

                  <td class="text-center">
                    <router-link
                      style="text-decoration: none; color: black"
                      :to="'/uploaddetail?id=' + value._id"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-2>
      <v-flex xs12>
        <v-pagination v-model="page" :length="pagelength"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyB-wSOSvoBXfA5vn-23GR7OU5qTm8BFwlk&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      myData: [],
      excel: {
        username: "",
        date: "",
        boatname: "",
        boatno: "",
        field: "",
        length: "",
      },

      json_fields: {
        Username: "username",
        Date: "date",
        Boat_Name: "boatname",
        Boat_No: "boatno",
        Spot: "field",
        Expected_Length: "length",
      },
      animalTypes: ["Shark", "megafauna"],
      animalType: null,
      spotTypes: ["In Net", "In Natural Water"],
      spotType: null,
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,

      headers: [
        { text: "Date", value: "create_date" },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
        },
        {
          text: "Boat name",
          align: "start",
          sortable: false,
          value: "boatname",
        },
        { text: "Boat No", sortable: false, value: "boatno" },
        { text: "Spot", sortable: false, value: "field" },
        { text: "Expected length (M)", value: "length" },
      ],
      //map data
      latt: null,
      lonn: null,
    };
  },
  watch: {
    page() {
      this.getData();
    },
    animalType() {
      this.getData();
    },
    spotType() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    this.getMap();
  },
  methods: {
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },
    rowClicked(item) {
      this.$router.push("/uploaddetail?id=" + item._id);
    },
    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("googlemap"), {
        center: { lat: 9.5260093, lng: 76.8144186 },
        zoom: 2,
      });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/record/view",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 10,
          type: this.animalType,
          field: this.spotType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.user;
            this.records = response.data.data;
            this.totalLength = response.data.totalLength;
            this.pagelength = Math.ceil(response.data.totalLength / 10);
            for (let i = 0; i < response.data.data.length; i++) {
              this.latt = response.data.data[i].location[1];
              this.lonn = response.data.data[i].location[0];
              // console.log("lat",response.data.data[i].location[1])
              // console.log("lon",response.data.data[i].location[0])

              this.icons = {
                // url: require("../../assets/images/whale.svg"),
                size: new google.maps.Size(46, 50),
                scaledSize: new google.maps.Size(32, 35),
                anchor: new google.maps.Point(0, 50),
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
              });

              const infowindow = new google.maps.InfoWindow({
                content: String(
                  "<h3>" +
                    this.records[i].boatname +
                    "</h3>" +
                    '<a href="/uploaddetail?id=' +
                    this.records[i]._id +
                    '"><button class="RB " style="color:#d39c00;font-size:12px" >View Details</button></a>'
                ),
                maxWidth: 500,
              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#googlemap {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
